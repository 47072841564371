import type { FunctionComponent } from 'react'
import { ArticleCard, Heading } from '@which/seatbelt'

import { newsletterLinkList, section } from '../../constants/newsletterLinks'
import styles from './NewsletterLinks.module.scss'

export const NewsletterLinks: FunctionComponent = () => (
  <section aria-labelledby="newsletterlinks-header">
    <Heading
      headingType="large"
      heading={section}
      headingTag="h2"
      subHeading="Get all kinds of free advice emailed to you regularly"
    />
    <nav aria-label="Newsletter links">
      <ul className={styles.list}>
        {newsletterLinkList.map((newsletterLink, ind) => {
          if (ind < 3) {
            return (
              <li key={`${newsletterLink.href}-variant`}>
                <ArticleCard
                  {...newsletterLink}
                  clampStrapline={false}
                  className={styles.newsletterLink}
                  mainLinkProps={{
                    'data-which-id': 'Homepage Card',
                    'data-section': section,
                    'data-card-name': newsletterLink.title,
                    'data-index': ind + 1,
                  }}
                />
              </li>
            )
          }
          return (
            <li key="all-newsletters-card">
              <ArticleCard
                href="https://www.which.co.uk/about-which/which-newsletters-akBCD7z3vgqS"
                image="https://media.product.which.co.uk/prod/images/original/ae0e792f71ad-homepageall-newsletters.jpg"
                imageAlt=""
                imageSources={[
                  {
                    sizes: '(min-width: 1024px) 260px, (min-width: 768px) 45vw, 90vw',
                    srcset:
                      'https://media.product.which.co.uk/prod/images/ar_2to1_320x160/ae0e792f71ad-homepageall-newsletters.webp 320w, https://media.product.which.co.uk/prod/images/ar_2to1_500x250/ae0e792f71ad-homepageall-newsletters.webp 500w, https://media.product.which.co.uk/prod/images/ar_2to1_700x350/ae0e792f71ad-homepageall-newsletters.webp 700w, https://media.product.which.co.uk/prod/images/ar_2to1_900x450/ae0e792f71ad-homepageall-newsletters.webp 900w, https://media.product.which.co.uk/prod/images/ar_2to1_1200x600/ae0e792f71ad-homepageall-newsletters.webp 1200w, https://media.product.which.co.uk/prod/images/ar_2to1_1500x750/ae0e792f71ad-homepageall-newsletters.webp 1500w, https://media.product.which.co.uk/prod/images/ar_2to1_1800x900/ae0e792f71ad-homepageall-newsletters.webp 1800w, https://media.product.which.co.uk/prod/images/ar_2to1_2100x1050/ae0e792f71ad-homepageall-newsletters.webp 2100w, https://media.product.which.co.uk/prod/images/ar_2to1_2400x1200/ae0e792f71ad-homepageall-newsletters.webp 2400w, https://media.product.which.co.uk/prod/images/ar_2to1_2700x1350/ae0e792f71ad-homepageall-newsletters.webp 2700w, https://media.product.which.co.uk/prod/images/ar_2to1_3000x1500/ae0e792f71ad-homepageall-newsletters.webp 3000w, https://media.product.which.co.uk/prod/images/ar_2to1_3300x1650/ae0e792f71ad-homepageall-newsletters.webp 3300w, https://media.product.which.co.uk/prod/images/ar_2to1_3600x1800/ae0e792f71ad-homepageall-newsletters.webp 3600w',
                  },
                ]}
                strapline="Expert tips from Which?"
                title="All newsletters"
                clampStrapline={false}
                className={styles.newsletterLink}
                mainLinkProps={{
                  'data-which-id': 'Homepage Card',
                  'data-section': section,
                  'data-card-name': 'All newsletters',
                  'data-index': 4,
                }}
              />
            </li>
          )
        })}
      </ul>
    </nav>
  </section>
)
