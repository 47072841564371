import type { ArticleCardProps } from '@which/seatbelt'

import { getImageSources } from '../../../shared/utils/getImageSources'

export const section = 'Newsletters'

const ratio = 'ar_2to1'
const renditions = [
  '320x160',
  '500x250',
  '700x350',
  '900x450',
  '1200x600',
  '1500x750',
  '1800x900',
  '2100x1050',
  '2400x1200',
  '2700x1350',
  '3000x1500',
  '3300x1650',
  '3600x1800',
]

export const newsletterLinkList: ArticleCardProps[] = [
  {
    href: 'https://signup.which.co.uk/wlp-weeklyscoop-newsletter',
    image:
      'https://media.product.which.co.uk/prod/images/original/59b73a57a841-frahomepagescoop.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('59b73a57a841-frahomepagescoop', ratio, renditions),
        sizes: '(min-width: 1024px) 260px, (min-width: 768px) 45vw, 90vw',
      },
    ],
    strapline: 'Make smarter consumer choices',
    title: 'Weekly Scoop newsletter',
  },
  {
    href: 'https://signup.which.co.uk/wlp-foodandhealthnewsletter',
    image:
      'https://media.product.which.co.uk/prod/images/original/b12f44ac33b9-fra-foodhealth-min.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('b12f44ac33b9-fra-foodhealth-min', ratio, renditions),
        sizes: '(min-width: 1024px) 260px, (min-width: 768px) 45vw, 90vw',
      },
    ],
    strapline: 'Eat right, live well and stay healthy',
    title: 'Food & health newsletter',
  },
  {
    href: 'https://signup.which.co.uk/wlp-cars-newsletter',
    image: 'https://media.product.which.co.uk/prod/images/original/55040906dafd-fra-cars.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('55040906dafd-fra-cars', ratio, renditions),
        sizes: '(min-width: 1024px) 260px, (min-width: 768px) 45vw, 90vw',
      },
    ],
    strapline: 'Drive smarter and cut costs',
    title: 'Cars newsletter',
  },
  {
    href: 'https://signup.which.co.uk/wlp-familynewsletter',
    image: 'https://media.product.which.co.uk/prod/images/original/4643dc0d982b-frahomepageall.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('4643dc0d982b-frahomepageall', ratio, renditions),
        sizes: '(min-width: 1024px) 260px, (min-width: 768px) 45vw, 90vw',
      },
    ],
    strapline: 'Keep costs down and kids happy',
    title: 'Family newsletter',
  },
]
