import type { FunctionComponent } from 'react'
import { AnimationWrapper, Heading, Picture, TypographyV2 as Typography } from '@which/seatbelt'

import { Link } from '../../../../shared/components/Link'
import { productLinkList, section } from '../../constants/productLinks'
import styles from './ProductLinks.module.scss'

export const ProductLinks: FunctionComponent = () => (
  <section aria-labelledby="productlinks-header">
    <div className={styles.headerWrapper}>
      <Heading
        headingType="large"
        heading={section}
        headingTag="h2"
        overline="Digital & full access members"
        subHeading="Find our Best Buys so you don't waste your money"
      />
    </div>
    <nav aria-labelledby="productlinks-header">
      <ul className={styles.list}>
        {productLinkList.map(({ href, image: { src, sources }, text }, ind) => (
          <li className={styles.listItem} key={href}>
            <Link
              href={href}
              className={styles.link}
              data-which-id="Homepage Link"
              data-section={section}
              data-card-name={text}
              data-index={ind + 1}
              appearance="primary"
              includeReactRouter={false}
              type="BaseLink"
            >
              <Picture
                aspectRatioMain="one-to-one"
                alt=""
                src={src}
                sources={sources}
                className={styles.imageContainer}
                imageClassName={styles.image}
              />
              <Typography textStyle="sb-text-body-default-strong">
                <AnimationWrapper>{text}</AnimationWrapper>
              </Typography>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  </section>
)
