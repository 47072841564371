import type { FunctionComponent } from 'react'
import React from 'react'

import { ToolCTA } from '../../../../shared/components/ToolCTA'

export const HomepageCTA: FunctionComponent<Props> = ({ title, button, ...rest }) => {
  const trackedButton = {
    ...button,
    'data-which-id': 'Homepage Button',
    'data-section': title,
    'data-card-name': button.text,
  }

  return (
    <ToolCTA title={title} button={trackedButton} {...rest} widgetImageAspectRatio="ar_2to1_" />
  )
}

type Props = Omit<Parameters<typeof ToolCTA>[0], 'widgetImageAspectRatio'>
