import type { FunctionComponent } from 'react'
import React from 'react'
import { FeaturedArticleCard, Grid, GridItem, Heading } from '@which/seatbelt'
import type { ImageWithSources } from '@which/shared'

import classnames from 'classnames'

import type {
  FeaturedContent,
  LatestNewsArticle,
  MainFeaturedArticle,
} from '../../../../generated/frontend'
import { LatestNews } from '../../../../shared/components/LatestNews'
import styles from './FeaturedLinks.module.scss'

export const FeaturedLinks: FunctionComponent<Props> = ({
  title,
  strapline,
  onwardLinkText,
  onwardLinkUrl,
  mainFeaturedArticle,
  featuredArticles,
  identifier,
  lazyLoad = true,
  backgroundColour,
}) => {
  const mappedArticles: LatestNewsArticle[] = featuredArticles.map((article, index) => ({
    ...article,
    'data-which-id': 'Homepage Card',
    'data-section': title,
    'data-card-name': article.title,
    'data-index': index + 2,
  }))

  const mappedMainFeaturedArticle: MappedMainFeaturedArticle = {
    href: mainFeaturedArticle.href,
    isLive: mainFeaturedArticle.isLive,
    standfirst: mainFeaturedArticle.standfirst,
    title: mainFeaturedArticle.title,
    date: mainFeaturedArticle.dateInfo?.rendered ?? '',
    dateTime: mainFeaturedArticle.dateInfo?.iso ?? '',
    ...(mainFeaturedArticle.image && {
      image: {
        alt: mainFeaturedArticle.image.alt,
        sources: mainFeaturedArticle.image.sources,
        src: mainFeaturedArticle.image.src,
        lazyLoad,
      },
    }),
  }

  return (
    <section
      aria-labelledby={`featured-links-header-${identifier}`}
      data-testid="featuredLinks"
      className={classnames(
        styles.featuredLinksWrapper,
        backgroundColour && `sb-bg-color-${backgroundColour}`
      )}
    >
      <Grid className="w-page-wrapper">
        <GridItem
          columnStart={{ large: 2 }}
          span={{ large: 10 }}
          className={styles.featuredLinksGridItem}
        >
          <Heading
            headingType="large"
            heading={title ?? ''}
            headingTag="h2"
            subHeading={strapline ?? ''}
          />
        </GridItem>
        <GridItem
          className={classnames(styles.featuredLinksGridItem, styles.featuredLinksArticleCard)}
          span={{ medium: 6, large: 5, xlarge: 5 }}
          columnStart={{ medium: 1, large: 2, xlarge: 2 }}
          aria-label={`${title} - Featured Article`}
        >
          <FeaturedArticleCard
            article={mappedMainFeaturedArticle}
            alignLeft={true}
            linkProps={{
              'data-which-id': 'Homepage Card',
              'data-section': title,
              'data-card-name': mappedMainFeaturedArticle.title,
              'data-index': 1,
            }}
          />
        </GridItem>
        <GridItem
          className={styles.featuredLinksGridItem}
          span={{ medium: 6, large: 5, xlarge: 5 }}
          columnStart={{ medium: 7, large: 7, xlarge: 7 }}
        >
          <LatestNews
            articles={mappedArticles}
            lazyLoad={lazyLoad}
            {...(onwardLinkUrl &&
              onwardLinkText && {
                navigationLink: {
                  text: onwardLinkText,
                  url: onwardLinkUrl,
                  'data-which-id': 'Homepage Link',
                  'data-section': title || '',
                  'data-card-name': onwardLinkText,
                  'data-index': featuredArticles.length + 2,
                },
              })}
          />
        </GridItem>
      </Grid>
    </section>
  )
}

///////// IMPLEMENTATION /////////

export type Props = {
  title?: string | null
  strapline?: string | null
  onwardLinkText?: string | null
  onwardLinkUrl?: string | null
  mainFeaturedArticle: MainFeaturedArticle
  featuredArticles: LatestNewsArticle[] | FeaturedContent[]
  identifier?: string | null
  lazyLoad?: boolean
  backgroundColour?: string | null
}

type MappedMainFeaturedArticle = Omit<MainFeaturedArticle, 'dateInfo' | 'image'> & {
  date: string
  dateTime: string
  image: ImageWithSources
}
