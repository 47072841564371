export const WEBSITE_SCHEMA = `{
  "@context": "https://schema.org",
  "@type": "WebSite",
  "name": "Which?",
  "url": "https://www.which.co.uk",
  "publisher": {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Which?",
    "slogan": "Expert testing, reviews and advice",
    "url": "https://www.which.co.uk",
    "publishingPrinciples": "https://www.which.co.uk/about-which/who-we-are-azQ8t7R93VDg",
    "logo": {
      "@context": "https://schema.org",
      "@type": "ImageObject",
      "url": "https://www.which.co.uk/static/images/logo.svg",
      "caption": "Which?"
    },
    "sameAs": [
      "https://www.facebook.com/whichuk/",
      "https://twitter.com/whichuk",
      "https://www.youtube.com/which",
      "https://www.pinterest.co.uk/whichuk/",
      "https://www.instagram.com/whichuk/",
      "https://www.tiktok.com/@whichuk"
    ],
    "email": "mailto:support@which.co.uk",
    "employees": [
      {
        "@type": "Person",
        "name": "Anabel Hoult",
        "jobTitle": "CEO",
        "image": "https://media.product.which.co.uk/prod/images/article_image_1560px/4a8d56e34709-anabel-houltabout-us.jpg",
        "sameAs": [
          "https://uk.linkedin.com/in/anabel-hoult/"
        ]
      }
    ],
    "location": [
      {
        "@type": "Place",
        "name": "Which?",
        "sameAs": "https://www.which.co.uk",
        "telephone": "+442922670000",
        "address": {
          "@type": "PostalAddress",
          "name": "Which?",
          "streetAddress": "2 Marylebone Road",
          "addressLocality": "London",
          "addressRegion": "Greater London",
          "postalCode": "NW1 4DF",
          "addressCountry": "United Kingdom"
        }
      }
    ]
  }
}`
