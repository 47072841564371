export const section = 'Pick the best products'

export const seoLinkList = [
  {
    href: 'https://www.which.co.uk/reviews/air-fryers/article/how-to-buy-the-best-air-fryer-ajltd1z3GrZm',
    text: 'Best air fryers',
  },
  {
    href: 'https://www.which.co.uk/reviews/broadband/article/best-broadband-providers-aIIx34f51krz',
    text: 'Best broadband',
  },
  {
    href: 'https://www.which.co.uk/reviews/new-and-used-cars/article/best-cars/top-cars-aaJ8y4F5Z8wg',
    text: 'Best cars',
  },
  {
    href: 'https://www.which.co.uk/reviews/child-car-seats/article/how-to-buy-the-best-car-seat-aGqe18f0GeQB',
    text: 'Best child car seats',
  },
  {
    href: 'https://www.which.co.uk/reviews/fitted-kitchens/article/fitted-kitchens/best-and-worst-kitchen-brands-am0wR5V2seJ3',
    text: 'Best fitted kitchens',
  },
  {
    href: 'https://www.which.co.uk/reviews/laptops/article/how-to-buy-the-best-laptop-aNXXi9d9wmfn',
    text: 'Best laptops',
  },
  {
    href: 'https://www.which.co.uk/reviews/lawn-mowers/article/how-to-buy-the-best-lawn-mower-aGhEi1V0rGcC',
    text: 'Best lawn mowers',
  },
  {
    href: 'https://www.which.co.uk/reviews/mattresses/article/how-to-buy-the-best-mattress-atlAf8V9fiZN',
    text: 'Best mattresses',
  },
  {
    href: 'https://www.which.co.uk/reviews/mobile-phone-providers/article/best-mobile-networks-overview-amhDx1F0z41t',
    text: 'Best mobile networks',
  },
  {
    href: 'https://www.which.co.uk/reviews/mobile-phones/article/choosing-the-best-mobile-phone/how-to-choose-the-best-mobile-phone-aqogx7F8J6yi',
    text: 'Best mobile phones',
  },
  {
    href: 'https://www.which.co.uk/reviews/built-in-ovens/article/how-to-buy-the-best-built-in-oven-aPwGQ2n80RDX',
    text: 'Best ovens',
  },
  {
    href: 'https://www.which.co.uk/reviews/supermarkets/article/best-and-worst-supermarkets/supermarkets-compared-aOJjN2J0A10N',
    text: 'Best supermarkets',
  },
  {
    href: 'https://www.which.co.uk/reviews/tumble-dryers/article/tumble-dryer-buying-guide/how-to-buy-the-best-tumble-dryer-a2Xa42O3YWK7',
    text: 'Best tumble dryers',
  },
  {
    href: 'https://www.which.co.uk/reviews/televisions/article/how-to-buy-the-best-tv/how-to-buy-the-best-tv-awTTN2d6a0u5',
    text: 'Best TVs',
  },
  {
    href: 'https://www.which.co.uk/reviews/washing-machines/article/which-washing-machine/which-washing-machine-should-you-buy-aafjl7E6UgGx',
    text: 'Best washing machines',
  },
]
