import { Picture, Typography } from '@which/seatbelt'

import type { MemberBenefitPageLink } from '../../../../../../generated/frontend'
import { Link } from '../../../../../../shared/components/Link'
import styles from './BenefitLink.module.scss'

interface MemberBenefitsLinkProps {
  link: MemberBenefitPageLink
  trackingData: {
    [key: string]: string
  }
}

export const BenefitLink = ({
  link: { image, text, href },
  trackingData,
}: MemberBenefitsLinkProps) => {
  return (
    <div className={styles.benefitLinkRow} data-testid="benefits-link-div">
      <Picture
        src={image}
        alt={text}
        aspectRatioMain="one-to-one"
        height="50"
        width="50"
        className={styles.benefitLinkRowIcon}
        imageClassName={styles.benefitLinkRowIconImage}
        aria-hidden
      />

      <Link href={href} data-testid="benefits-link" {...trackingData}>
        <Typography tag="span" textStyle="sb-text-body-default-strong">
          {text}
        </Typography>
      </Link>
    </div>
  )
}
