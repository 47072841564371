import type { FunctionComponent } from 'react'
import { Heading, Picture, TypographyV2 as Typography } from '@which/seatbelt'

import { Link } from '../../../../shared/components/Link'
import { lifeAdminLinksData, popularLinksData } from '../../constants/popularOnWhich'
import styles from './PopularOnWhich.module.scss'

export const PopularOnWhich: FunctionComponent = () => (
  <>
    {[popularLinksData, lifeAdminLinksData].map(({ id, title, subheading, links }) => (
      <section className={styles.section} key={id}>
        <div id={id}>
          <Heading headingType="large" heading={title} headingTag="h2" subHeading={subheading} />
        </div>
        <nav aria-labelledby={id} className={styles.navSection}>
          <ul className={styles.list}>
            {links.map(({ href, text, icon }, ind) => (
              <li className={styles.listItem} key={href}>
                <Link
                  className={styles.link}
                  href={href}
                  data-which-id="Homepage Link"
                  data-section={title}
                  data-card-name={text}
                  data-index={ind + 1}
                  appearance="primary"
                  type="BaseLink"
                >
                  <Picture
                    className={styles.picture}
                    src={icon}
                    width="50"
                    height="50"
                    aria-hidden
                  />
                  <Typography tag="h3" textStyle="sb-text-heading-small">
                    {text}
                  </Typography>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </section>
    ))}
  </>
)
