export const testUserAgent = (regex: RegExp, userAgent: string) => {
  return userAgent != null && regex.test(userAgent)
}

export const isMac = (userAgent: string) => {
  return testUserAgent(/Macintosh/i, userAgent)
}

export const isIPhone = (userAgent: string) => {
  return testUserAgent(/iPhone/i, userAgent)
}

export const isIPad = (userAgent: string) => {
  return testUserAgent(/iPad/i, userAgent)
}

export const isApple = (userAgent: string) => {
  return isIPhone(userAgent) || isIPad(userAgent) || isMac(userAgent)
}

export const isAndroid = (userAgent: string) => {
  return testUserAgent(/Android/i, userAgent)
}

export const isChrome = (userAgent: string) => {
  return testUserAgent(/Chrome/i, userAgent) && !isEdge(userAgent) && !isOpera(userAgent)
}

export const isFirefox = (userAgent: string) => {
  return testUserAgent(/Firefox/i, userAgent)
}

export const isEdge = (userAgent: string) => {
  return testUserAgent(/Edg/i, userAgent)
}

export const isOpera = (userAgent: string) => {
  return testUserAgent(/Opera|OPR\//i, userAgent)
}

export const isSafari = (userAgent: string) => {
  return (
    testUserAgent(/Safari/i, userAgent) &&
    !isChrome(userAgent) &&
    !isFirefox(userAgent) &&
    !isEdge(userAgent) &&
    !isOpera(userAgent)
  )
}
