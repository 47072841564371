import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useHomePageQuery } from '../../generated/frontend'
import { ErrorComponent, PageError } from '../../shared/components/Error'
import { Loader } from '../../shared/components/Loader'
import { PageTemplate } from '../../shared/templates/PageTemplate'
import type { PageInfo } from '../../shared/types/PageInfo'
import { isIPad, isIPhone, isSafari } from '../../shared/utils/detect-device'
import { useHomePageComponents } from './hooks/useHomePageComponents'
import { useHomePageMetaTags } from './hooks/useHomePageMetaTags'

const HomePage: FunctionComponent = () => {
  const { loading, error, data } = useHomePageQuery()
  const { getComponents } = useHomePageComponents()
  const { getMetaTags } = useHomePageMetaTags()

  const [userAgent, setUserAgent] = useState('')

  useEffect(() => {
    setUserAgent(navigator.userAgent)
  }, [])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (!data?.homePage) {
    return <PageError pageName="Home" />
  }

  const { homePage } = data

  const { dataLayer = [] } = homePage.meta || {}

  const login = dataLayer.find((item) => item?.loginStatus)
  const pageInfo: PageInfo = {
    vertical: 'homepage',
    content_type: 'homepage',
    pageType: homePage.meta.pageType ?? '',
  }

  const HomePageWithSmartBanner = () => {
    const displayIosSmartBanner = useFeatureIsOn('display-ios-smart-banner')

    const metaTags = displayIosSmartBanner
      ? [
          ...getMetaTags(homePage.meta, homePage.imageUrl),
          <meta name="apple-itunes-app" content="app-id=1082710781, app-argument=which://" />,
        ]
      : getMetaTags(homePage.meta, homePage.imageUrl)

    return (
      <PageTemplate
        metaTags={metaTags}
        components={getComponents(homePage)}
        gamConfig={{ pageInfo, login }}
        showResubBanner={homePage.showResubBanner}
      />
    )
  }

  const HomePageWithoutSmartBanner = () => {
    return (
      <PageTemplate
        metaTags={getMetaTags(homePage.meta, homePage.imageUrl)}
        components={getComponents(homePage)}
        gamConfig={{ pageInfo, login }}
        showResubBanner={homePage.showResubBanner}
      />
    )
  }

  const isSafariIOS = isSafari(userAgent) && (isIPhone(userAgent) || isIPad(userAgent))

  return isSafariIOS ? <HomePageWithSmartBanner /> : <HomePageWithoutSmartBanner />
}

export default HomePage
