import type { FunctionComponent } from 'react'
import React from 'react'
import { ContentCardV2, Heading } from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import { Link } from '../../../../shared/components/Link'
import { campaignLinkList, section } from '../../constants/campaignLinks'
import styles from './CampaignLinks.module.scss'

export const CampaignLinks: FunctionComponent = () => (
  <section aria-labelledby="campaignlinks-header">
    <Heading
      headingType="large"
      heading={section}
      headingTag="h2"
      subHeading="Let's work together to change things"
    />
    <nav aria-labelledby="campaignlinks-header">
      <ul className={styles.list}>
        {campaignLinkList.map((campaignLink, index) => (
          <li className={styles.listItem} key={campaignLink.primaryLink}>
            <ContentCardV2
              {...campaignLink}
              arrangement="horizontal"
              trackingData={{
                'data-which-id': 'Homepage Card',
                'data-section': section,
                'data-card-name': campaignLink.title,
                'data-index': `${index + 1}`,
              }}
            />
          </li>
        ))}
      </ul>
      <Link
        href="https://campaigns.which.co.uk/"
        icon={ChevronRightIcon}
        data-which-id="Homepage Link"
        data-section={section}
        data-card-name="All campaigns"
        data-index={campaignLinkList.length + 1}
        type="NavigationLink"
      >
        All campaigns
      </Link>
    </nav>
  </section>
)
