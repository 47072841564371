import type { ComponentPropsWithoutRef, FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import {
  ButtonLink,
  CardContainer,
  Heading,
  Picture,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import classnames from 'classnames'
import type { DOMNode, HTMLReactParserOptions } from 'html-react-parser'
import parse, { domToReact, Element } from 'html-react-parser'
import DOMPurify from 'isomorphic-dompurify'

import type { ImageWithSources } from '../../../../generated/frontend'
import { Link } from '../../../../shared/components/Link'
import { isAndroid, isIPhone } from '../../../../shared/utils/detect-device'
import styles from './MemberBenefitsLoggedIn.module.scss'

export const MemberBenefitsLoggedIn: FunctionComponent<Props> = ({
  title,
  calloutText,
  buttonLink,
  className,
  headingId,
  memberBenefitsLoggedIn,
  memberOffer,
  onwardJourney,
  ...rest
}) => {
  const parseOnwardJourney = () => {
    if (!onwardJourney) {
      return null
    }

    let linkIndex = memberBenefitsLoggedIn.length
    const sanitizedHTML = DOMPurify.sanitize(onwardJourney)
    const options: HTMLReactParserOptions = {
      replace: (domNode: DOMNode) => {
        if (domNode instanceof Element && domNode.name === 'a') {
          linkIndex = linkIndex + 1
          const { href } = domNode.attribs
          return (
            <Link
              href={href}
              data-testid="standard-link-primary"
              data-which-id="Homepage Link"
              data-card-name={domNode.children[0].data}
              data-section={title}
              data-index={`${linkIndex}`}
            >
              {domToReact(domNode.children as DOMNode[])}
            </Link>
          )
        }
      },
    }
    return (
      <Typography textStyle="sb-text-body-default-regular">
        {parse(sanitizedHTML, options)}
      </Typography>
    )
  }

  return (
    <article
      aria-labelledby={headingId}
      className={classnames(styles.panel, className)}
      {...rest}
      data-testid="mem-benefits-logged-in"
    >
      <div className={styles.panelHeadingWrapper}>
        <Typography
          id={headingId}
          tag="h2"
          textStyle="sb-text-heading-small"
          className={styles.panelTitle}
        >
          {title}
        </Typography>
      </div>

      <div className={styles.benefits}>
        <div className={styles.benefitsWrapper}>
          {memberBenefitsLoggedIn.map((item, index, array) => {
            const isLastItem = index === array.length - 1

            if (
              memberOffer?.isActive &&
              (memberOffer?.offerText || memberOffer?.offerTitle) &&
              memberOffer?.href &&
              isLastItem
            ) {
              return (
                <MemberBenefitsExclusiveOfferRow
                  key={`member-benefit-${index}`}
                  memberOffer={memberOffer}
                  index={index}
                  section={title}
                />
              )
            }

            return (
              <MemberBenefitsRow
                key={`member-benefit-${index}`}
                section={title}
                {...item}
                index={index}
              />
            )
          })}
        </div>
      </div>

      <div className={styles.panelAccountStrapline}>
        <Typography tag="p" textStyle="sb-text-body-default-regular">
          {calloutText}
        </Typography>
      </div>

      {onwardJourney ? (
        parseOnwardJourney()
      ) : (
        <ButtonLink
          href={buttonLink?.href}
          className={styles.panelAccountButton}
          aria-label="myAccount"
          data-which-id="Homepage Link"
          data-card-name={buttonLink?.text}
          data-section={title}
          data-index={`${memberBenefitsLoggedIn.length + 1}`}
        >
          <ChevronRightIcon /> {buttonLink?.text}
        </ButtonLink>
      )}
    </article>
  )
}

const MemberBenefitsExclusiveOfferRow: FunctionComponent<MemberOfferProps> = ({
  memberOffer: { offerTitle, offerText, href, image },
  section,
  index,
}) => {
  if (!offerText && !offerTitle) {
    return null
  }

  return (
    <div className={styles.MemberBenefitsExclusiveOfferRow} data-testid="member-exclusive-offer">
      <Heading headingTag="h3" headingType="small" heading="Member exclusive offer:" />

      <CardContainer
        ariaLabel={offerText}
        id={'member-exclusive-offer'}
        arrangement={'center'}
        primaryLink={href}
        trackingData={{
          'data-which-id': 'Homepage Card',
          'data-card-name': offerTitle || '',
          'data-section': section,
          'data-index': `${index + 1}`,
        }}
      >
        {image && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <RenderPicture image={image} />
          </div>
        )}

        {offerTitle && <Heading headingTag="h4" headingType="small" heading={offerTitle} />}

        {offerText && (
          <Typography tag="p" textStyle="sb-text-body-small-regular">
            {offerText}
          </Typography>
        )}
      </CardContainer>
    </div>
  )
}

const MemberBenefitsRow: FunctionComponent<MemberBenefitsProps> = ({
  section,
  href,
  icon,
  title,
  index,
}) => {
  const [memberBenefitsAppLink, setMemberBenefitsAppLink] = useState<string>('')

  useEffect(() => {
    const { userAgent } = navigator
    const defaultAppLink: string = 'https://signup.which.co.uk/app-download'
    const iosAppLink: string =
      'https://apps.apple.com/app/apple-store/id1082710781?pt=813236&ct=homepage-member-benefits-panel&mt=8'
    const androidAppLink: string =
      'https://play.google.com/store/apps/details?id=uk.co.which.reviews&referrer=utm_source%3Dwhich-website%26utm_medium%3Dreferral%26utm_campaign%3Dhomepage-member-benefits-panel%26anid%3Dadmob'

    if (isIPhone(userAgent)) {
      setMemberBenefitsAppLink(iosAppLink)
    } else if (isAndroid(userAgent)) {
      setMemberBenefitsAppLink(androidAppLink)
    } else {
      setMemberBenefitsAppLink(defaultAppLink)
    }
  }, [])

  const createAppLink = () => {
    if (title === 'Get the Which? app') {
      return memberBenefitsAppLink
    }

    return href
  }

  return (
    <div className={styles.benefitsRow}>
      <RenderPicture image={icon} />

      <Link
        href={createAppLink()}
        className={styles.benefitsRowLink}
        data-testid="standard-link-primary"
        data-which-id="Homepage Link"
        data-card-name={title}
        data-section={section}
        data-index={`${index + 1}`}
      >
        <Typography
          tag="span"
          textStyle="sb-text-body-default-strong"
          className={styles.benefitsRowText}
        >
          {title}
        </Typography>
      </Link>
    </div>
  )
}

const RenderPicture: FunctionComponent<{ image: ImageWithSources }> = ({ image }) => {
  return (
    <Picture
      src={image.src}
      alt={image.alt}
      sources={image.sources}
      aspectRatioMain="one-to-one"
      height="50"
      width="50"
      className={styles.benefitsRowIcon}
      imageClassName={styles.benefitsRowIconImage}
      aria-hidden
    />
  )
}

///////// IMPLEMENTATION /////////

export type MemberBenefitLoggedIn = {
  icon: ImageWithSources
  href: string
  title: string
}

export type Props = {
  title: string
  overlineText: string
  headingId: string
  isLoggedIn?: boolean
  caption?: string
  calloutText?: string
  buttonLink?: {
    text: string
    href: string
  }
  memberOffer?: {
    href: string | undefined
    image: ImageWithSources | null
    isActive: boolean
    offerText: string | undefined
    offerTitle: string | undefined
  }
  memberBenefitsLoggedIn: MemberBenefitLoggedIn[]
  onwardJourney: string | undefined
} & ComponentPropsWithoutRef<'div'>

type MemberBenefitsProps = {
  section: string
  icon: ImageWithSources
  href: string
  title: string
  index: number
}

type MemberOfferProps = {
  index: number
  memberOffer: {
    href: string | undefined
    image: ImageWithSources | null
    offerTitle: string | undefined
    offerText: string | undefined
  }
  section: string
}
