import type { FunctionComponent } from 'react'
import React from 'react'
import { FeaturedArticleCard, Grid, GridItem } from '@which/seatbelt'
import type { ImageWithSources } from '@which/shared'

import { useFeatureValue } from '@growthbook/growthbook-react'
import classnames from 'classnames'

import type {
  FeaturedContent,
  LatestNewsArticle,
  MainFeaturedArticle,
} from '../../../../generated/frontend'
import { LatestNews } from '../../../../shared/components/LatestNews'
import { MemberBenefits } from '../MemberBenefits'
import { MemberBenefitsLoggedIn } from '../MemberBenefitsLoggedIn'
import { PersonalisedMemberBenefits } from '../PersonalisedMemberBenefits/PersonalisedMemberBenefits'
import styles from './FeaturedLinksWithMemberBenefits.module.scss'

export const FeaturedLinksWithMemberBenefits: FunctionComponent<Props> = ({
  featuredLinks: {
    title,
    onwardLinkText,
    onwardLinkUrl,
    mainFeaturedArticle,
    featuredArticles,
    identifier,
    lazyLoad = true,
    backgroundColour,
  },
  memberBenefits,
  memberBenefitsLoggedIn,
}) => {
  const personalisedMemberBenefits = useFeatureValue('personalised-member-benefits', false)

  const mappedArticles: LatestNewsArticle[] = featuredArticles.map((article, index) => ({
    ...article,
    'data-which-id': 'Homepage Card',
    'data-section': title,
    'data-card-name': article.title,
    'data-index': index + 2,
  }))

  const mappedMainFeaturedArticle: MainFeaturedArticleMapped = {
    href: mainFeaturedArticle.href,
    isLive: mainFeaturedArticle.isLive,
    standfirst: mainFeaturedArticle.standfirst,
    title: mainFeaturedArticle.title,
    date: mainFeaturedArticle.dateInfo.rendered,
    dateTime: mainFeaturedArticle.dateInfo.iso,
    ...(mainFeaturedArticle.image && {
      image: {
        alt: mainFeaturedArticle.image.alt,
        sources: mainFeaturedArticle.image.sources,
        src: mainFeaturedArticle.image.src,
        lazyLoad,
      },
    }),
  }

  const renderMemberBenefits = () => {
    if (personalisedMemberBenefits) {
      return <PersonalisedMemberBenefits />
    }

    if (memberBenefits.isLoggedIn) {
      return (
        <MemberBenefitsLoggedIn
          {...memberBenefitsLoggedIn}
          headingId="homepage-member-benefits-heading"
          className={styles.memberBenefits}
        />
      )
    }

    return (
      <MemberBenefits
        {...memberBenefits}
        headingId="homepage-member-benefits-heading"
        className={styles.memberBenefits}
      />
    )
  }

  return (
    <section
      aria-labelledby={`featured-links-header-${identifier}`}
      data-testid="featuredLinks"
      className={classnames(styles.wrapper, backgroundColour && `sb-bg-color-${backgroundColour}`)}
    >
      <Grid className={classnames(styles.grid, 'w-page-wrapper')}>
        <GridItem
          columnStart={{ medium: 1, large: 2, xlarge: 2 }}
          span={{ medium: 8, large: 6, xlarge: 7 }}
          className={styles.mobileMaxWidthWrapper}
        >
          <FeaturedArticleCard
            className={styles.featuredLinksArticleCard}
            article={mappedMainFeaturedArticle}
            alignLeft={true}
            linkProps={{
              'data-which-id': 'Homepage Card',
              'data-section': title,
              'data-card-name': mappedMainFeaturedArticle.title,
              'data-index': 1,
            }}
          />
          <LatestNews
            className={styles.featuredLinksLatestNews}
            articles={mappedArticles}
            aspectRatioMain="four-to-three"
            lazyLoad={lazyLoad}
            {...(onwardLinkUrl &&
              onwardLinkText && {
                navigationLink: {
                  text: onwardLinkText,
                  url: onwardLinkUrl,
                  'data-which-id': 'Homepage Link',
                  'data-section': title || '',
                  'data-card-name': onwardLinkText,
                  'data-index': featuredArticles.length + 2,
                },
              })}
          />
        </GridItem>
        <GridItem
          columnStart={{ medium: 9, large: 8, xlarge: 9 }}
          span={{ medium: 4, large: 4, xlarge: 3 }}
          className={classnames(styles.mobileMaxWidthWrapper, styles.topSpacing)}
        >
          {renderMemberBenefits()}
        </GridItem>
      </Grid>
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  featuredLinks: {
    title?: string | null
    strapline?: string | null
    onwardLinkText?: string | null
    onwardLinkUrl?: string | null
    mainFeaturedArticle: MainFeaturedArticle
    featuredArticles: LatestNewsArticle[] | FeaturedContent[]
    identifier?: string | null
    lazyLoad?: boolean
    backgroundColour?: string | null
  }
  memberBenefits: Omit<Parameters<typeof MemberBenefits>[0], 'headingId'>
  memberBenefitsLoggedIn: Omit<Parameters<typeof MemberBenefitsLoggedIn>[0], 'headingId'>
}

type MainFeaturedArticleMapped = Omit<MainFeaturedArticle, 'dateInfo' | 'image'> & {
  date: string
  dateTime: string
  image: ImageWithSources
}
