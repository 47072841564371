import { Heading, Link, Picture, Typography } from '@which/seatbelt'

import styles from './PrizesChristmas.module.scss'

export const heading = 'Feeling lucky?'
export const offerText = '12 Prizes of Christmas competition'
export const offerURL =
  'https://signup.which.co.uk/12-prizes-of-christmas?internalReferral=homepage_12_prizes_xmas'
export const imageAlt = 'christmas stocking'
export const imageSrc =
  'https://media.product.which.co.uk/prod/images/original/0bc4588b306f-boots.png'
export const membersText = 'For Which? members only, 1 to 12 Dec 2024'

interface PrizesChristmasProps {
  trackingData: {
    [key: string]: string
  }
}

export const PrizesChristmas = ({ trackingData }: PrizesChristmasProps) => {
  return (
    <div className={styles.wrapper} data-testid="prizes-christmas">
      <div className={styles.row}>
        <div className={styles.column}>
          <Heading headingTag="h4" headingType="small" heading={heading} />
          <Typography tag="p" textStyle="sb-text-body-small-regular">
            Enter our{' '}
            <Link
              href={offerURL}
              textStyle="sb-text-body-small-regular"
              {...trackingData}
              data-card-name={offerText}
            >
              {offerText}
            </Link>{' '}
            and you could win up to 12 prizes in 12 days
          </Typography>
        </div>
        <Picture
          src={imageSrc}
          alt={imageAlt}
          height="100"
          width="62"
          className={styles.imageWrapper}
          imageClassName={styles.image}
          aria-hidden
        />
      </div>
      <Typography tag="p" textStyle="sb-text-body-x-small-regular" data-testid="members-only">
        {membersText}
      </Typography>
    </div>
  )
}
