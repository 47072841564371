import type { FunctionComponent } from 'react'
import React from 'react'
import { Heading } from '@which/seatbelt'

import { Link } from '../../../../shared/components/Link'
import { section, seoLinkList } from '../../constants/seoLinks'
import styles from './SeoLinks.module.scss'

export const SeoLinks: FunctionComponent = () => (
  <section aria-labelledby="seolinks-header">
    <div className={styles.headerWrapper}>
      <Heading
        headingType="large"
        heading={section}
        headingTag="h2"
        overline="Expert Buying Advice"
        subHeading="Know what to look for when you're shopping around"
      />
    </div>
    <nav aria-label="Best buy links">
      <ul className={styles.list}>
        {seoLinkList.map(({ href, text }, ind) => (
          <li className={styles.listItem} key={href}>
            <Link
              href={href}
              data-which-id="Homepage Link"
              data-section={section}
              data-card-name={text}
              data-index={ind + 1}
            >
              {text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  </section>
)
