import type { FunctionComponent } from 'react'
import { Heading, Typography } from '@which/seatbelt'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import type { MemberBenefitsInfoQuery } from '../../../../../../generated/frontend'
import { BenefitLink } from '../BenefitLink/BenefitLink'
import { OfferBox } from '../OfferBox/OfferBox'
import { PrizesChristmas } from '../PrizesChristmas/PrizesChristmas'
import styles from './PersonalisedMemberBenefitsLoggedIn.module.scss'

type PersonalisedMemberBenefitsLoggedInProps = {
  links?: MemberBenefitsInfoQuery['memberBenefitsInfo']['links']
  offer?: MemberBenefitsInfoQuery['memberBenefitsInfo']['offer']
}

export const PersonalisedMemberBenefitsLoggedIn: FunctionComponent<
  PersonalisedMemberBenefitsLoggedInProps
> = ({ links, offer, ...rest }) => {
  const isPrizesChristmas = useFeatureIsOn('WHC-480-prizes-christmas')
  const title = 'Get the most from your membership'
  const accountLinkText = 'your account'
  const appointmentLinkText = 'book an appointment'
  let linkIndex = 0

  const renderOffer = () => {
    if (isPrizesChristmas) {
      linkIndex = linkIndex + 1

      return (
        <PrizesChristmas
          trackingData={{
            'data-which-id': 'Homepage Link',
            'data-section': title,
            'data-index': `${linkIndex}`,
          }}
        />
      )
    }

    if (!offer) {
      return null
    } else {
      linkIndex = linkIndex + 1

      return (
        <div className={styles.panelSection}>
          <Heading headingTag="h3" headingType="small" heading="Member exclusive offer:" />
          <OfferBox
            offer={offer}
            trackingData={{
              'data-which-id': 'Homepage Card',
              'data-card-name': offer.heading,
              'data-section': title,
              'data-index': `${linkIndex}`,
            }}
          />
        </div>
      )
    }
  }

  return (
    <article aria-labelledby={'member-benefits-heading'} className={styles.panel} {...rest}>
      <div className={styles.panelHeadingWrapper}>
        <Typography
          id="member-benefits-heading"
          tag="h2"
          textStyle="sb-text-heading-small"
          className={styles.panelTitle}
        >
          {title}
        </Typography>
      </div>
      {links ? (
        <div className={styles.panelSection}>
          {links.map((link, i) => {
            if (link.__typename == 'MemberBenefitPageLink') {
              linkIndex = linkIndex + 1
              return (
                <BenefitLink
                  link={link}
                  key={i}
                  trackingData={{
                    'data-which-id': 'Homepage Link',
                    'data-card-name': link.text,
                    'data-section': title,
                    'data-index': `${linkIndex}`,
                  }}
                />
              )
            }
            console.error('Unexpected link type for logged in member benefits')
            return null
          })}
        </div>
      ) : null}
      {renderOffer()}
      <div className={styles.panelSection}>
        <Typography textStyle="sb-text-body-default-regular">
          See all the details of your membership in{' '}
          <a
            href="/account/"
            data-which-id="Homepage Link"
            data-card-name={accountLinkText}
            data-section={title}
            data-index={`${linkIndex + 1}`}
          >
            {accountLinkText}
          </a>{' '}
          or{' '}
          <a
            href="/my-appointments#upcoming-appointments"
            data-which-id="Homepage Link"
            data-card-name={appointmentLinkText}
            data-section={title}
            data-index={`${linkIndex + 2}`}
          >
            {appointmentLinkText}
          </a>
        </Typography>
      </div>
    </article>
  )
}
